import React, { useState } from 'react';
import { assetConstants } from '@chainflip/utils/chainflip';
import { formatUsdValue } from '@chainflip/utils/number';
import { BigNumber } from 'bignumber.js';
import { startOfHour, subDays } from 'date-fns';
import { flip$ } from '@/shared/assets/tokens';
import { SkeletonLine } from '@/shared/components';
import { useGqlQuery } from '@/shared/hooks/useGqlQuery';
import { useHistoricalPrice } from '@/shared/hooks/useHistoricalPrice';
import useTokenPrice from '@/shared/hooks/useTokenPrice';
import {
  ChartIcon,
  CoinsIcon,
  DollarIcon,
  FireIcon,
  LockIcon,
  SwapIcon,
} from '@/shared/icons/large';
import { TrendingDownIcon, TrendingUpIcon } from '@/shared/icons/small';
import { FLIP_SYMBOL, TokenAmount, chainflipAssetMap, formatWithNumeral } from '@/shared/utils';
import { useFlipContract } from 'packages/block-explorer/hooks/useFlipContract';
import { getCirculatingSupplyQuery, getProtocolStatsQuery } from '../../queries/protocol';

const Card = ({
  title,
  value,
  icon,
  footer,
  isLoading,
}: {
  title: string;
  value: string;
  icon: React.ReactNode;
  footer?: React.ReactNode;
  isLoading?: boolean;
}) => (
  <div className="flex flex-col gap-y-2 rounded-md border border-cf-gray-3-5 bg-cf-gray-2 p-4 text-center md:text-left">
    <div className="flex items-center justify-center gap-x-1 md:justify-normal">
      {icon}
      <div className="text-14 text-cf-light-2">{title}</div>
    </div>
    {!isLoading ? (
      <>
        <div className="text-16 text-cf-light-4 md:text-24">{value}</div>
        <div className="flex items-center justify-center gap-x-1 text-12 md:justify-normal">
          {footer}
        </div>
      </>
    ) : (
      <SkeletonLine className="max-w-[80px]" height={28} />
    )}
  </div>
);

const ProtocolStatCards = () => {
  const [today] = useState<Date | null>(new Date());
  const { price } = useTokenPrice(chainflipAssetMap.Flip);
  const { totalFundedFlip, isLoading: isTotalLockedLoading } = useFlipContract();
  const { historicalPrice } = useHistoricalPrice(
    flip$,
    subDays(startOfHour(today ?? 0), 1).toISOString(),
  );

  const { data: protocolStats, isLoading: isProtocolStatsLoading } = useGqlQuery(
    getProtocolStatsQuery,
    {
      variables: {
        maxTimestamp: subDays(today ?? 0, 1).toISOString(),
      },
      enabled: Boolean(today),
    },
  );

  const { data: circulation, isLoading: isCirculationLoading } = useGqlQuery(
    getCirculatingSupplyQuery,
    {
      context: { clientName: 'statechainCache' },
    },
  );

  const lockedFlipUsdValue = new BigNumber(price ?? 0)
    .times(totalFundedFlip?.toFixed(2) ?? 0)
    .toFixed(2);

  const circulatingSupply = circulation?.circulation?.circulatingSupply
    ? `${formatWithNumeral(
        new TokenAmount(
          circulation?.circulation?.circulatingSupply,
          assetConstants.Flip.decimals,
        ).toFixed(2),
      )} ${FLIP_SYMBOL}`
    : 'N/A';

  const totalSupply = formatWithNumeral(
    new TokenAmount(
      circulation?.circulation?.totalSupply || 0,
      assetConstants.Flip.decimals,
    ).toFixed(2),
  );

  const totalBurn = new TokenAmount(
    protocolStats?.currentBurn?.aggregates?.sum?.amount ?? 0,
    assetConstants.Flip.decimals,
  );

  const historicalBurn = new TokenAmount(
    protocolStats?.historicalBurn?.aggregates?.sum?.amount ?? 0,
    assetConstants.Flip.decimals,
  );

  const burnDelta = totalBurn.sub(historicalBurn);

  const volume = BigNumber.sum(
    protocolStats?.oneLegSwaps?.aggregates?.sum?.swapOutputValueUsd ?? 0,
    protocolStats?.twoLegSwaps?.aggregates?.sum?.intermediateValueUsd ?? 0,
    protocolStats?.twoLegSwaps?.aggregates?.sum?.swapOutputValueUsd ?? 0,
  );

  const historicalVolume = BigNumber.sum(
    protocolStats?.historicalOneLegSwaps?.aggregates?.sum?.swapOutputValueUsd ?? 0,
    protocolStats?.historicalTwoLegSwaps?.aggregates?.sum?.intermediateValueUsd ?? 0,
    protocolStats?.historicalTwoLegSwaps?.aggregates?.sum?.swapOutputValueUsd ?? 0,
  );

  const priceDelta = new BigNumber(price ?? 0)
    .minus(historicalPrice?.FLIP?.usdPrice ?? 0)
    .dividedBy(historicalPrice?.FLIP?.usdPrice ?? 0)
    .times(100);

  const volumeDelta = volume.minus(historicalVolume);

  const count =
    2n * BigInt(protocolStats?.twoLegSwaps?.aggregates?.distinctCount?.id ?? 0) +
    BigInt(protocolStats?.oneLegSwaps?.aggregates?.distinctCount?.id ?? 0);

  const historicalCount =
    2n * BigInt(protocolStats?.historicalTwoLegSwaps?.aggregates?.distinctCount?.id ?? 0) +
    BigInt(protocolStats?.historicalOneLegSwaps?.aggregates?.distinctCount?.id ?? 0);

  const countDiff = formatWithNumeral((count - historicalCount).toString());

  const isFlipPriceLoading = price === undefined || historicalPrice === null;

  return (
    <div className="grid grid-cols-2 gap-5 md:grid-cols-3">
      <Card
        title="Volume / All time"
        value={formatUsdValue(volume, false)}
        icon={<ChartIcon className="h-[16px] w-[16px] text-cf-green-1 md:h-[unset] md:w-[unset]" />}
        isLoading={isProtocolStatsLoading}
        footer={
          <>
            <span className="text-cf-light-2">Last 24H</span>
            <TrendingUpIcon className="text-cf-green-3" />
            <span className="text-cf-green-3">+{formatUsdValue(volumeDelta, false)}</span>
          </>
        }
      />
      <Card
        title="Swaps / All time"
        value={formatWithNumeral(count)}
        icon={<SwapIcon className="h-[16px] w-[16px] text-cf-orange-2 md:h-[unset] md:w-[unset]" />}
        isLoading={isProtocolStatsLoading}
        footer={
          <>
            <span className="text-cf-light-2">Last 24H</span>
            <TrendingUpIcon className="text-cf-green-3" />
            <span className="text-cf-green-3">+{countDiff} swaps</span>
          </>
        }
      />
      <Card
        title={`Staked ${FLIP_SYMBOL}`}
        isLoading={isTotalLockedLoading}
        value={`${formatWithNumeral(totalFundedFlip?.toFixed(2) ?? 0)} ${FLIP_SYMBOL}`}
        icon={<LockIcon className="h-[16px] w-[16px] text-cf-blue-2 md:h-[unset] md:w-[unset]" />}
        footer={<div className="text-cf-light-2">{formatUsdValue(lockedFlipUsdValue, false)}</div>}
      />
      <Card
        title="FLIP Price"
        value={formatUsdValue(price) ?? ''}
        icon={
          <DollarIcon className="h-[16px] w-[16px] text-cf-green-3 md:h-[unset] md:w-[unset]" />
        }
        isLoading={isFlipPriceLoading}
        footer={
          <>
            <span className="text-cf-light-2">Last 24H</span>
            {priceDelta.isNegative() ? (
              <TrendingDownIcon className="text-cf-red-2" />
            ) : (
              <TrendingUpIcon className="text-cf-green-3" />
            )}
            <span className={priceDelta.isNegative() ? 'text-cf-red-2' : 'text-cf-green-3'}>
              {priceDelta.toFixed(2)}%
            </span>
          </>
        }
      />
      <Card
        title={`Total ${FLIP_SYMBOL} Burned`}
        isLoading={isProtocolStatsLoading}
        value={formatWithNumeral(totalBurn.toFixed(2))}
        icon={<FireIcon className="h-[16px] w-[16px] text-cf-red-2 md:h-[unset] md:w-[unset]" />}
        footer={
          <>
            <span className="text-cf-light-2">Last 24H</span>
            <TrendingUpIcon className="text-cf-green-3" />
            <span className="text-cf-green-3">+{burnDelta.toFixed(2)}</span>
          </>
        }
      />
      <Card
        title="Circulating Supply"
        value={circulatingSupply}
        icon={<CoinsIcon className="h-[16px] w-[16px] text-cf-blue-1 md:h-[unset] md:w-[unset]" />}
        isLoading={isCirculationLoading}
        footer={
          circulatingSupply !== 'N/A' && (
            <span className="text-cf-light-2">
              Out of <span className="ml-0.5 text-cf-light-3">{totalSupply}</span>
            </span>
          )
        }
      />
    </div>
  );
};

export default ProtocolStatCards;
